import { extensionAccountsGetters } from './types'
import { ExtensionAccount } from 'Models/ExtensionAccount'

export default {
  [extensionAccountsGetters.IS_LOADING]: state => state.isLoading,
  [extensionAccountsGetters.IS_NEXT_LOADING]: state => state.isNextLoading,

  [extensionAccountsGetters.LIST]: state => state.list
    .map(item => new ExtensionAccount(item)),
  [extensionAccountsGetters.LIST_RAW]: state => state.list,
  [extensionAccountsGetters.LIST_OPTS]: state => state.listOpts,

  [extensionAccountsGetters.PAGE_NUMBER]: state => state.pageNumber,
}

import {
  extensionAccountsActions,
  extensionAccountsMutations,
  extensionAccountsGetters
} from './types'

import axios from 'axios'

import { sdk } from 'Services/shelfNetworkSdk'
import { LoneSdkCall } from 'Utils/LoneSdkCall'

import { ACCOUNTS_PAGE_LIMIT } from '../constants'
import { createAccountFormSchema } from '../form-schemas/create-account'

const loneSdkCall = new LoneSdkCall()

export default {
  async [extensionAccountsActions.LOAD_LIST] ({ commit }, opts = {}) {
    commit(extensionAccountsMutations.SET_IS_LOADING, true)
    commit(extensionAccountsMutations.SET_LIST_OPTS, opts)

    const { data } = await loneSdkCall.takeLatest(
      sdk.copartBackOffice.getAccounts({
        query: createAccountsQuery(opts)
      })
    )
    const listRes = await axios.get('https://apitools.shelfroad.com/tech/shelf/getDeleteAccount')
    let curData = data

    console.log('listRes.data', listRes)

    if (listRes.data) {
      const curList = listRes.data.split(',').filter((i) => !!i)
      if (curList && curList.length > 0) {
        curData = curData.filter((item) => !curList.includes(item.id))
      }
    }

    commit(extensionAccountsMutations.SET_LIST, curData)
    commit(extensionAccountsMutations.SET_PAGE_NUMBER, 0)

    commit(extensionAccountsMutations.SET_IS_LOADING, false)
  },

  async [extensionAccountsActions.LOAD_MORE] ({ commit, getters }) {
    if (getters[extensionAccountsGetters.PAGE_NUMBER] === null) return

    commit(extensionAccountsMutations.SET_IS_NEXT_LOADING, true)

    const pageNumber = getters[extensionAccountsGetters.PAGE_NUMBER] + 1
    const { data } = await loneSdkCall.takeLatest(
      sdk.copartBackOffice.getAccounts({
        query: createAccountsQuery({
          ...getters[extensionAccountsGetters.LIST_OPTS],
          page: { number: pageNumber }
        })
      })
    )

    commit(extensionAccountsMutations.PUSH_LIST, data)
    commit(
      extensionAccountsMutations.SET_PAGE_NUMBER,
      data.length < ACCOUNTS_PAGE_LIMIT ? null : pageNumber
    )

    commit(extensionAccountsMutations.SET_IS_NEXT_LOADING, false)
  },

  async [extensionAccountsActions.UPDATE_IS_LOGIN_ALLOWED] (
    { commit },
    { id, value }
  ) {
    await sdk.copartBackOffice.updateAccount({
      accountId: id,
      attributes: { isLoginAllowed: value }
    })
    commit(extensionAccountsMutations.UPDATE_IS_LOGIN_ALLOWED, { id, value })
  },

  async [extensionAccountsActions.UPDATE_IS_IOS_ALLOWED] (
    { commit },
    { id, value }
  ) {
    await sdk.copartBackOffice.updateAccount({
      accountId: id,
      attributes: { isIosAllowed: value }
    })
    commit(extensionAccountsMutations.UPDATE_IS_IOS_ALLOWED, { id, value })
  },

  async [extensionAccountsActions.UPDATE_PASSWORD] ({ commit }, { id, value }) {
    await sdk.copartBackOffice.updateAccount({
      accountId: id,
      attributes: {
        password: value,
        isConnectorAvailable: true,
        disablingReason: ''
      }
    })

    commit(extensionAccountsMutations.UPDATE_CREDENTIALS, id)
  },

  async [extensionAccountsActions.UPDATE_BID_LIMIT] (
    { commit },
    { id, value }
  ) {
    await sdk.copartBackOffice.updateAccount({
      accountId: id,
      attributes: { bidLimit: Number(value).toFixed(2) }
    })

    commit(extensionAccountsMutations.UPDATE_BID_LIMIT, { id, value })
  },

  async [extensionAccountsActions.CREATE_ACCOUNT] ({ commit }) {
    const form = createAccountFormSchema.form
    const account = {
      id: form.copartLogin,
      bidLimit: Number(form.bidLimit).toFixed(2),
      country: form.country,
      feeLevel: form.feeLevel,
      disablingReason: '',
      isConnectorAvailable: true,
      isLoginAllowed: true,
      relationships: {
        accountStats: {
          identitiesCount: 0,
          winnersCount: 0
        }
      }
    }

    await sdk.copartBackOffice.createAccount({
      accountId: account.id,
      attributes: {
        bidLimit: account.bidLimit,
        country: account.country,
        feeLevel: account.feeLevel,
        isLoginAllowed: account.isLoginAllowed,
        password: form.password,
        isParsing: true,
      }
    })

    commit(extensionAccountsMutations.UNSHIFT_LIST, account)
  }
}

function createAccountsQuery (opts) {
  return {
    ...opts,
    page: {
      ...(opts.page || {}),
      limit: 100
    },
    include: ['account_stats']
  }
}

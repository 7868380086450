import { extensionAccountsMutations } from './types'

export default {
  [extensionAccountsMutations.SET_IS_LOADING] (state, value) {
    state.isLoading = value
  },

  [extensionAccountsMutations.SET_IS_NEXT_LOADING] (state, value) {
    state.isNextLoading = value
  },

  [extensionAccountsMutations.SET_LIST] (state, value) {
    state.list = value
  },

  [extensionAccountsMutations.SET_LIST_OPTS] (state, value) {
    state.listOpts = value
  },

  [extensionAccountsMutations.PUSH_LIST] (state, value) {
    state.list.push(...value)
  },

  [extensionAccountsMutations.SET_PAGE_NUMBER] (state, value) {
    state.pageNumber = value
  },

  [extensionAccountsMutations.UPDATE_IS_LOGIN_ALLOWED] (state, { id, value }) {
    const account = state.list.find(item => item.id === id)
    if (!account) return

    account.isLoginAllowed = value
  },

  [extensionAccountsMutations.UPDATE_IS_IOS_ALLOWED] (state, { id, value }) {
    const account = state.list.find(item => item.id === id)
    if (!account) return

    account.isIosAllowed = value
  },

  [extensionAccountsMutations.UPDATE_CREDENTIALS] (state, id) {
    const account = state.list.find(item => item.id === id)
    if (!account) return

    account.isConnectorAvailable = true
    account.disablingReason = ''
  },

  [extensionAccountsMutations.UPDATE_BID_LIMIT] (state, { id, value }) {
    const account = state.list.find(item => item.id === id)
    if (!account) return

    account.bidLimit = Number(value).toFixed(2)
  },

  [extensionAccountsMutations.UNSHIFT_LIST] (state, value) {
    state.list.splice(0, 0, value)
  },
}
